<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="120px" :rules="rules">
      <el-form-item label="所属商户" prop="shopId">
        <SelectTdShop v-model="formData.shopId" @selected-type="handleSelectedType" width="250px"></SelectTdShop>
      </el-form-item>
      <el-form-item label="商品名称" prop="name">
        <el-input style="width: 250px" size="mini" v-model="formData.name" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <div v-if="showTypeOther" style="margin-left: 30px; width: 500px;" >
        <p style="text-align: center;">商品内容</p>
        <el-form-item label="商品" label-width="80px" v-for="(item, index) in formData.productContent" :key="index">
        <el-input style="width: 250px" size="mini" v-model="item.name" clearable placeholder="请输入"></el-input>
        <el-button 
          size="mini" 
          type="danger" 
          icon="el-icon-delete" 
          @click="removeItem(index)" 
          class="delete-btn">
          删除
        </el-button>
      </el-form-item>
      <el-button type="primary" size="small" @click="addItem" style="margin-left: 340px;">增加</el-button>
      <!-- <el-form-item label="其他" label-width="80px">
        <el-input style="width: 250px" size="mini" v-model="formData.productOther" clearable placeholder="请输入"></el-input>
      </el-form-item> -->
      </div>
      <div v-if="showTypeWangKa" style="display: flex ">
        <div style="width: 56px; margin-left: 40px;margin: auto auto auto 40px" >
          电脑配置
        </div>
        <div style="flex-grow: 1;">
          <el-form-item label="处理器" label-width="90px">
                  <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.computerSpec" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="显卡" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.graphicsCard" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="内存" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.memory" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="显示器" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.monitor" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="键盘" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.keyboard" clearable  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="鼠标" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.mouse"  clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="耳机" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.headset"  clearable placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>
        <div v-if="showTypeDianJing" style="display: flex ">
        <div style="width: 56px; margin-left: 40px;margin: auto auto auto 40px" >
          电脑配置
        </div>
        <div style="flex-grow: 1;">
          <el-form-item label="电脑数量" label-width="90px">
                <el-input-number style="width: 250px" size="mini" v-model="formData.computerSpec.computerNum" clearable placeholder="请输入"></el-input-number>
          </el-form-item>
          <el-form-item label="处理器型号" label-width="90px">
                  <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.computerSpec" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="显卡型号" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.graphicsCard" clearable placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="显示器参数" label-width="90px">
              <el-input style="width: 250px" size="mini" v-model="formData.computerSpec.monitor" clearable placeholder="请输入"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item v-if="showTypeDianJing"   label="床位规格和数量">
        <el-input style="width: 250px" size="mini" v-model="formData.bedSpec" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item v-if="showTypeDianJing"  label="卫生间">
        <el-select style="width: 250px" v-model="formData.toiletType" placeholder="请选择" size="mini" clearable>
            <el-option label="独立卫生间" :value="1"> </el-option>
          <el-option label="无卫生间" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item  v-if="showTypeDianJing"  label="限住人数">
        <el-input-number style="width: 250px" size="mini" v-model="formData.limitPeople" clearable placeholder="请输入"></el-input-number>
      </el-form-item>


        <el-form-item label="停车场">
        <el-select style="width: 250px" v-model="formData.parkingType" placeholder="请选择" size="mini" clearable>
          <el-option label="付费停车" :value="1"> </el-option>
          <el-option label="免费停车" :value="2"> </el-option>
          <el-option label="无停车场" :value="3"> </el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="其他">
        <el-input style="width: 250px" size="mini" v-model="formData.productOther" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="使用时间限制">
        <el-input style="width: 250px" size="mini" v-model="formData.userLimit" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否可以吸烟">
        <el-input style="width: 250px" size="mini" v-model="formData.xiYang" clearable placeholder="请输入"></el-input>
      </el-form-item>
        <el-form-item label="门市原价">
        <el-input-number :precision="2" style="width: 250px" size="mini" v-model="formData.originalPrice" clearable placeholder="请输入"></el-input-number>
      </el-form-item>
        <el-form-item label=" 商品售卖价格">
        <el-input-number :precision="2" style="width: 250px" size="mini" v-model="formData.price" clearable placeholder="请输入"></el-input-number>
      </el-form-item>
        <el-form-item label="商品封面图">
          <UploadImg :url.sync="formData.cover"></UploadImg>
        </el-form-item>
      <el-form-item label="商品图片">
        <UploadImgs :background_images.sync="formData.images"></UploadImgs>
      </el-form-item>
      <el-form-item label="">
        <el-button type="" size="small" @click="$router.back()">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import UploadImgs from '@/components/qiniu-uploader/uploader-imgs.vue'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
import SelectTdShop from '@/views/td/shop-list/select-tdshop.vue'
export default {
  name: 'Update',
  components: { UploadImgs, UploadImg, SelectTdShop },
  data() {
    return {
      formData: {
        name: '',
        shopId: this.$route.query.shopId,
        images: [],
        cover: '',
        productContent: [
          {
            "name": ""
          }
        ],
        productOther: '',
        others: '',
        parkingType: null,
        toiletType: null,
        limitPeople: 0, //  限住人数
        xiYang: '',
        userLimit: '',
        num: 0,
        type: 0,
        originalPrice: 0,
        price : 0,
        bedSpec : '',
        computerSpec: {
          computerNum: null,
          computerSpec: '',
          graphicsCard: '',
          monitor: '',
          memory: '',
          keyboard: '',
          mouse: '',
          headset: '',
      }
      },
      type: 0,
      showTypeOther: false,
      showTypeWangKa: false,
      showTypeDianJing: false,
      rules: {
        shopId: [
          { trigger: 'blur', message: '选择商户', required: true }
        ],
        name: [
          { trigger: 'blur', message: '商品名称必填', required: true }
        ]
      },
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  methods: {
    addItem () {
      this.formData.productContent.push({ name: '' });
    },
    removeItem(index) {
      this.formData.productContent.splice(index, 1);
    },
    handleSelectedType(type) {
      this.formData.type = type;
      this.showTypeOther = false
      this.showTypeDianJing = false;
      this.showTypeWangKa = false;
      // 0:其他类型商家 1：电竞酒店 2：网卡电竞馆
      if (type === 0) {
        this.showTypeOther = true;
        this.showTypeDianJing = false;
        this.showTypeWangKa = false;
      } else if (type === 1) {
        this.showTypeDianJing = true;
      } else if (type === 2) {
        this.showTypeWangKa = true;
      }
    },
    save() {
      this.formData.images = this.formData.images.map((item) => item.url)
      // this.formData.productContent = this.formData.productContent.map((item) => item.name)

      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail () {
      this.showTypeOther = false;
      this.showTypeDianJing = false;
      this.showTypeWangKa = false;

      this.formData = await getDetailAPI(this.$route.params.id)
      this.formData.images = this.formData.images.map((item) => {
        return {
          url: item
        }
      })
      console.log(this.formData.images, 1111)
      if (!this.formData.productContent) {
        this.formData.productContent = [{ name: '' }]
      }

      // this.formData.productContent = this.formData.productContent.map((item) => {
      //   return {
      //     name: item
      //   }
      // })
      const type = this.formData.type
      if (type === 0) {
        this.showTypeOther = true;
      } else if (type === 1) {
        this.showTypeDianJing = true;
      } else if (type === 2) {
        this.showTypeWangKa = true;
      }
    }
  }
}
</script>
<style scoped>
.form-item-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.delete-btn {
  margin-left: 10px;
}

</style>